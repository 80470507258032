import Header from "../layout/header";
import Footer from "../layout/footer";
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
// import moment from "moment";
import Seo from "../seo";
import RandomImage from "../../images/tnfy-blog-random.png";

const Index = (props) => {
  const data = props.pageContext.data;
  const [category, setCategory] = useState("All");
  const title = "Blog - Teenyfy";
  const description =
    "Stay updated with Teenyfy's blog for the latest news and updates! Dive into insightful content and stay ahead in the digital realm. Visit us!";
  var canonical = "/blog";

  const [theArray, setTheArray] = useState([]);

  const newElement = {
    node: {
      id: "1",
      slug: "https://app.teenyfy.com/user/register",
      short_image: {
        url: RandomImage,
      },
    },
  };

  useEffect(() => {
    const insert = (arr, index, newItem) => [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index),
    ];
    //change positions using index values at 0
    const result = insert(data, 1, newElement);

    setTheArray(result);
  }, [data]);
  // console.log(theArray);

  const tags = ["All", "Inspiration", "Updates", "How To"];

  const filteredArray = theArray?.filter((item) =>
    item?.node?.tags?.some((tag) => tag.name === category)
  );
  // console.log(filteredArray);
  return (
    <React.Fragment>
      <Seo title={title} description={description} slug={canonical} />
      <Header />
      <div className="flex items-center justify-center w-11/12 2xl:w-9/12 mx-auto">
        <div className="flex justify-center md:flex-row flex-col w-full max-w-screen-2xl mt-2 mb-8">
          <div className="xl:px-0 mt-5">
            <div className="flex justify-center my-9">
              <div className="w-full">
                <h1 className="font-roboto text-center text-5xl mt-3">
                  Teenyfy Blog
                </h1>
                <div className="flex justify-center gap-1 mt-6 md:gap-4">
                  {tags?.map((tag) => (
                    <p
                      className="px-4 py-1 bg-[#D9D9D9] rounded-full max-w-fit text-[14px] cursor-pointer"
                      key={tag}
                      onClick={() => setCategory(tag)}
                    >
                      {tag}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <ul className="grid grid-cols-1 md:mt-20 md:grid-cols-2 xl:grid-cols-3 gap-x-[60px] px-4">
              {category === "All"
                ? theArray?.map(({ node }, index) => {
                    const {
                      id,
                      // publishedAt,
                      short_description,
                      slug,
                      title,
                      short_image,
                      tags,
                    } = node;
                    return (
                      <li
                        className="sm:w-[336px] border-newBorder border-b-3 mb-7 flex justify-center"
                        key={id}
                        id={index}
                      >
                        <div>
                          {title ? (
                            <Link to={slug}>
                              <div className="text-xl font-roboto w-[270px] h-[108px] font-bold py-2 text-[#1F1F39] leading-[28px] block">
                                {title}
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}

                          <Link to={slug}>
                            <OptimizedImage
                              src={short_image.url}
                              alt={title}
                              className={`w-full object-cover ${
                                id == 1 ? "h-full" : "h-[336px]"
                              }  rounded-[20px]`}
                            />
                          </Link>
                          <div className="flex gap-1 mt-4">
                            {tags?.map(({ name }, index) => (
                              <p className="px-4 py-1 bg-[#D9D9D9] rounded-full max-w-fit">
                                {name}
                              </p>
                            ))}
                          </div>
                          {/* <p className="pb-2 inline-block font-light font-roboto">
                      {moment(publishedAt).format("DD MMM YYYY")}
                    </p> */}

                          {short_description ? (
                            <Link to={slug}>
                              <p className="pb-5 mt-2 pt-1 text-[#1F1F3980] font-roboto font-normal leading-[28px]">
                                {short_description}
                                <span className="inline-flex items-center text-sm italic pb-5 font-roboto text-[#28ADA7]">
                                  {" "}
                                  &nbsp; read more
                                </span>
                              </p>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    );
                  })
                : filteredArray?.map(({ node }, index) => {
                    const {
                      id,
                      // publishedAt,
                      short_description,
                      slug,
                      title,
                      short_image,
                      tags,
                    } = node;
                    return (
                      <li
                        className="sm:w-[336px] border-newBorder border-b-3 mb-7 flex justify-center"
                        key={id}
                        id={index}
                      >
                        <div>
                          {title ? (
                            <Link to={slug}>
                              <div className="text-xl font-roboto w-[270px] h-[108px] font-bold py-2 text-[#1F1F39] leading-[28px] block">
                                {title}
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}

                          <Link to={slug}>
                            <OptimizedImage
                              src={short_image.url}
                              alt={title}
                              className={`w-full object-cover ${
                                id == 1 ? "h-full" : "h-[336px]"
                              }  rounded-[20px]`}
                            />
                          </Link>
                          <div className="flex gap-1 mt-4">
                            {tags?.map(({ name }, index) => (
                              <p className="px-4 py-1 bg-[#D9D9D9] rounded-full max-w-fit">
                                {name}
                              </p>
                            ))}
                          </div>
                          {/* <p className="pb-2 inline-block font-light font-roboto">
                      {moment(publishedAt).format("DD MMM YYYY")}
                    </p> */}

                          {short_description ? (
                            <Link to={slug}>
                              <p className="pb-5 mt-2 pt-1 text-[#1F1F3980] font-roboto font-normal leading-[28px]">
                                {short_description}
                                <span className="inline-flex items-center text-sm italic pb-5 font-roboto text-[#28ADA7]">
                                  {" "}
                                  &nbsp; read more
                                </span>
                              </p>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    );
                  })}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-11/12 sm:w-10/12 md:w-11/12 md:px-3 md:max-w-2xl lg:max-w-none xl:max-w-6xl mx-auto lg:w-full">
        <div className="space-y-4 bg-[#EAFAFA] my-12 py-12 px-8 rounded-2xl">
          <h2 className="text-4xl">
            Make Your Links More <br /> Memorable
          </h2>
          <p className="text-semibold">
            Shorten your urls into branded, readable format
            <br /> with{" "}
            <Link to="/" className="text-[#3D948E]">
              Teenyfy
            </Link>
          </p>{" "}
          <a
            href="https://app.teenyfy.com/user/register"
            className="bg-[#3D948E] px-12 py-3 rounded-3xl text-white inline-block"
          >
            Shorten Link
          </a>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};
export default Index;
